import * as React from 'react'
import Layout from '@/components/Layout'
import Header from '@/components/Header'
import About from '@/components/About'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
const Ho = () => {
  return (
    <Layout>
      <SEO title="About" canonical={useLocation().host} />
      <Header />
      <About />
      <Footer />
    </Layout>
  )
}

export default Ho
