import Image from '@/components/Image'
import React from 'react'

const Start = () => {
  return (
    <section className="section-padding section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="image mb-xl-30">
              <Image name='ab.webp'/>
              {/* <img src="/images/ab.webp" alt="img" className="image-fit" /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-header">
              {/* <p className="section_count"></p> */}
              <h3 className="title">About Us</h3>
            </div>
            <p>
              Jet Air MRO provides long-term, cost-effective maintenance, repair, and overhaul
              services for commercial aircraft components and accessories. We are experienced
              professionals committed to responsiveness within the shortest possible timeframe while
              maintaining the highest industry standards of quality, safety, and efficiency. Our
              solutions guarantee the total satisfaction of our clients at all times.
            </p>
            <div className="row">
              <div className="col-sm-6">
                <div className="icon_box">
                  <img src="images/reliability-icon.svg" />
                  <h5 className="title mb-0">
                    Reliable <br /> Services
                  </h5>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon_box">
                  <img src="images/optimization-icon.svg" />
                  <h5 className="title mb-0">
                    Process
                    <br /> Optimization
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Start
