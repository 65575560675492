import React from 'react'
import SubHeader from '@/components/Common/SubHeader'
import Start from '@/components/About/Start'
// import Testimonial from '@/components/Home/Testimonial'
import Team from '@/components/Home/Team'

const About =()=> {
  return (
    <>
    <SubHeader title='About Us'/>
    <Start/>
    {/* <Testimonial/> */}
    <Team/>
    </>)
}

export default About